import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import styled from 'styled-components';

import { ProductCropWrapper as ProductImageCrop } from './content/files/crop';

import { app } from '~common/app.model';
import Modal from '~sections/Modal';
import { useActions } from '~common/utils/hooks.utils';
import Portal from '~common/misc/Portal';

const SelectCategoryModal = React.lazy(
  () => import('./content/categoryTree/select')
);
const WorkspaceShare = React.lazy(
  () => import('./content/workspaces/modals/share')
);
const WorkspaceAdd = React.lazy(
  () => import('./content/workspaces/modals/add')
);
const WorkspaceSave = React.lazy(
  () => import('./content/workspaces/modals/save')
);
const WorkspaceCopy = React.lazy(
  () => import('./content/workspaces/modals/copy')
);
const WorkspaceEdit = React.lazy(
  () => import('./content/workspaces/modals/edit')
);
const WorkspaceOpenConfirm = React.lazy(
  () => import('./content/workspaces/modals/open')
);
const WorkspaceOpen = React.lazy(
  () => import('./content/workspaces/modals/workspacetree')
);
const ShareHistoryModal = React.lazy(
  () => import('./content/workspaces/modals/history')
);
const ContentDelete = React.lazy(
  () => import('./content/common/confirmModals/deleteModal')
);
const ContentMove = React.lazy(
  () => import('./content/common/confirmModals/moveModal')
);
const ContentCopy = React.lazy(
  () => import('./content/common/confirmModals/copyModal')
);
const FileSetPassword = React.lazy(() => import('./content/files/SetPassword'));
const FileView = React.lazy(() => import('./content/files/view/FileViewModal'));
const VersionsView = React.lazy(
  () => import('./content/files/view/VersionsModal')
);
const FileAttachmentsModal = React.lazy(
  () => import('./content/files/attachments')
);
const FileAttachmentEditModal = React.lazy(
  () => import('./content/files/attachments/edit')
);
const FileCrop = React.lazy(() => import('./content/files/crop'));
const FileShare = React.lazy(() => import('./content/files/share'));
const FolderEdit = React.lazy(() => import('./content/folders/edit'));
const FolderAdd = React.lazy(() => import('./content/folders/add'));
const FolderSelect = React.lazy(() => import('./content/folders/select'));
const EditNameModal = React.lazy(
  () => import('./content/files/common/EditNameModal')
);
const CsvImportModal = React.lazy(
  () => import('./content/products/editMassImport/CsvImportModal')
);
const ImageMetadataModal = React.lazy(
  () => import('./content/files/view/ImageMetadataModal')
);
const OrdersAdd = React.lazy(() => import('./orders/add'));
const SendConsentRequestModal = React.lazy(
  () => import('./content/consentRequests/SendConsentRequestModal')
);
const PersonTagModal = React.lazy(
  () => import('./content/consentRequests/personTags/PersonTagModal')
);
const Search = React.lazy(() => import('./content/search/SearchModal'));
const EditProductNameModal = React.lazy(
  () => import('./content/products/EditProductNameModal')
);
const ProductSave = React.lazy(
  () => import('./content/products/settings/SaveModal')
);
const ProductAfterSave = React.lazy(
  () => import('./content/products/settings/AfterSaveModal')
);
const ProductResize = React.lazy(
  () => import('./content/products/settings/ResizeModal')
);
const DownloadModal = React.lazy(() => import('./content/files/download'));
const DeepDownloadModal = React.lazy(
  () => import('./content/files/deepDownload')
);
const FolderTreeModal = React.lazy(() => import('./content/FolderTreeModal'));
const ArchiveModal = React.lazy(() => import('./content/archive/ArchiveModal'));
const ArchiveQueueModal = React.lazy(
  () => import('./content/archive/ArchiveQueueModal')
);

const ConfirmModal = React.lazy(() => import('./common/misc/ConfirmModal'));
const PromptModal = React.lazy(() => import('./common/misc/PromptModal'));
const CommentShare = React.lazy(() => import('./comments/edit/share'));
const CommentEditRoles = React.lazy(
  () => import('./comments/overview/EditRolesModal')
);
const CommentEditClosingTime = React.lazy(
  () => import('./comments/overview/EditClosingTimeModal')
);
const NewsModal = React.lazy(() => import('./news/NewsModal'));
const MetaFieldInfoModal = React.lazy(
  () => import('./content/common/MetaFieldInfoModal')
);
const AlertModal = React.lazy(() => import('./common/misc/AlertModal'));
const TermsOfUseModal = React.lazy(() => import('./settings/TermsOfUseModal'));
const FolderRightsModal = React.lazy(
  () => import('./settings/rights/FolderRightsModal')
);
const ManageGroupUsersModal = React.lazy(
  () => import('./users/ManageGroupUsersModal')
);
const GroupRightsModal = React.lazy(
  () => import('./settings/rights/GroupRightsModal')
);
const InternalShareModal = React.lazy(
  () => import('./settings/rights/InternalShareModal')
);
const FilePickerModal = React.lazy(
  () => import('./orders/add/FilePickerModal')
);
const UserEditModal = React.lazy(() => import('./users/UserEditModal'));
const NewsEditModal = React.lazy(() => import('./news/NewsEditModal'));
const UserChangePasswordModal = React.lazy(
  () => import('./users/UserChangePasswordModal')
);
const ExportUsersModal = React.lazy(() => import('./users/UserExportModal'));
const ShareUploadFormModal = React.lazy(
  () => import('./content/uploadForms/ShareUploadFormModal')
);
const JCDHelpInfoModal = React.lazy(
  () => import('./integration/jcdecaux/components/JCDHelpInfoModal')
);
const CustomerSpecificActionModal = React.lazy(
  () => import('./content/files/CustomerSpecificAction')
);
const ManageConsentTermModal = React.lazy(
  () => import('./content/consentRequests/consentTerms/ManageConsentTermModal')
);
const ReadConsentTermModal = React.lazy(
  () => import('./content/consentRequests/consentTerms/ReadConsentTermModal')
);
const AssignConsentTermModal = React.lazy(
  () => import('./content/consentRequests/consentTerms/AssignConsentTermModal')
);

const ManageTaggedPersonModal = React.lazy(
  () => import('./content/consentRequests/personTags/ManageTaggedPersonModal')
);

const DeclineConsentRequestModal = React.lazy(
  () => import('./content/consentRequests/DeclineConsentRequestModal')
);

const AddProjectDirectoryModal = React.lazy(
  () => import('./content/projectDirectory/ProjectDirectoryModal')
);

const UNSKIPPABLE_MODAL_TYPES = ['SETTINGS/TERMS_OF_USE'];

const getModalComponent = (modalType: string) => {
  switch (modalType) {
    case 'ARCHIVE/QUEUE':
      return ArchiveQueueModal;
    case 'SEARCH':
      return Search;
    case 'FILE/VIEW':
      return FileView;
    case 'FILE/VERSIONS':
      return VersionsView;
    case 'FILE/MOVE':
      return FolderTreeModal;
    case 'FILE/COPY':
      return FolderTreeModal;
    case 'FILE/CROP':
      return FileCrop;
    case 'FILE/SHARE':
      return FileShare;
    case 'FILE/EDIT_NAME':
      return EditNameModal;
    case 'FILE/IMAGE_META':
      return ImageMetadataModal;
    case 'FILE/PASSWORD':
      return FileSetPassword;
    case 'FILE/ATTACHMENTS':
      return FileAttachmentsModal;
    case 'ATTACHMENT/EDIT':
      return FileAttachmentEditModal;
    case 'FOLDER/COPY':
      return WorkspaceCopy;
    case 'WORKSPACE/SHARE':
      return WorkspaceShare;
    case 'WORKSPACE/SAVE':
      return WorkspaceSave;
    case 'WORKSPACE/COPY':
      return WorkspaceCopy;
    case 'WORKSPACE/CONFIRM_OPEN':
      return WorkspaceOpenConfirm;
    case 'WORKSPACE/OPEN_WORKSPACE':
      return WorkspaceOpen;
    case 'WORKSPACE/EDIT':
      return WorkspaceEdit;
    case 'WORKSPACE/SHARE_HISTORY':
      return ShareHistoryModal;
    case 'WORKSPACE/INTERNAL_SHARE':
      return InternalShareModal;
    case 'WORKSPACE/ADD':
      return WorkspaceAdd;
    case 'PRODUCT/EDIT':
      return OrdersAdd;
    case 'PRODUCT/EDIT_NAME':
      return EditProductNameModal;
    case 'PRODUCT/COPY_OR_RENAME':
      return ProductSave;
    case 'PRODUCT/AFTER_COPY_OR_RENAME':
      return ProductAfterSave;
    case 'PRODUCT/RESIZE':
      return ProductResize;
    case 'PRODUCT/IMAGE_CROP':
      return ProductImageCrop;
    case 'FOLDER/EDIT':
      return FolderEdit;
    case 'FOLDER/ADD':
      return FolderAdd;
    case 'FOLDER/SELECT':
      return FolderSelect;
    case 'FOLDER/IMAGE_SELECT':
      return FilePickerModal;
    case 'CONTENT/ARCHIVE':
      return ArchiveModal;
    case 'CONTENT/DOWNLOAD':
      return DownloadModal;
    case 'CONTENT/DEEP_DOWNLOAD':
      return DeepDownloadModal;
    case 'CONTENT/CONFIRM_DELETE':
      return ContentDelete;
    case 'CONTENT/CONFIRM_MOVE':
      return ContentMove;
    case 'CONTENT/CONFIRM_COPY':
      return ContentCopy;
    case 'COMMON/CONFIRM_MODAL':
      return ConfirmModal;
    case 'COMMON/PROMPT_MODAL':
      return PromptModal;
    case 'COMMON/ALERT_MODAL':
      return AlertModal;
    case 'COMMENTS/SHARE':
      return CommentShare;
    case 'COMMENTS/EDIT_ROLES':
      return CommentEditRoles;
    case 'COMMENTS/EDIT_CLOSING':
      return CommentEditClosingTime;
    case 'NEWS/UNREAD_NEWS':
    case 'NEWS/OPEN_NEWS':
      return NewsModal;
    case 'CONTENT/METAFIELD_INFO':
      return MetaFieldInfoModal;
    case 'SELECT/CATEGORY':
      return SelectCategoryModal;
    case 'SETTINGS/TERMS_OF_USE':
      return TermsOfUseModal;
    case 'SETTINGS/FOLDER_RIGHTS':
      return FolderRightsModal;
    case 'SETTINGS/MANAGE_GROUP_USERS':
      return ManageGroupUsersModal;
    case 'SETTINGS/GROUP_RIGHTS':
      return GroupRightsModal;
    case 'ORDERS/ATTACHMENTS':
      return FilePickerModal;
    case 'USERS/EDIT':
      return UserEditModal;
    case 'USERS/CHANGE_PASSWORD':
      return UserChangePasswordModal;
    case 'USERS/EXPORT':
      return ExportUsersModal;
    case 'FORMS/SEND_UPLOAD_FORM':
      return ShareUploadFormModal;
    case 'INTEGRATION/JCDECAUX/HELP_INFO':
      return JCDHelpInfoModal;
    case 'CONTENT/CUSTOMER_SPECIFIC_ACTION':
      return CustomerSpecificActionModal;
    case 'PRODUCT/CSV_IMPORT':
      return CsvImportModal;
    case 'CONSENT/MANAGE_TERMS':
      return ManageConsentTermModal;
    case 'CONSENT/READ_TERMS':
      return ReadConsentTermModal;
    case 'CONSENT/ASSIGN_TERMS':
      return AssignConsentTermModal;
    case 'CONSENT/MANAGE_TAGGED_PERSONS':
      return ManageTaggedPersonModal;
    case 'CONSENT/DECLINE_REQUEST':
      return DeclineConsentRequestModal;
    case 'CONSENT/SEND_REQUEST':
      return SendConsentRequestModal;
    case 'CONSENT/TAG_PERSONS':
      return PersonTagModal;
    case 'NEWS/EDIT':
      return NewsEditModal;
    case 'PROJECT_DIRECTORY/ADD':
      return AddProjectDirectoryModal;
    default:
      return null;
  }
};

const ModalLoadingSpinner = () => (
  <Portal>
    <ModalLoadingSpinnerWrapper>
      <CircularProgress />
    </ModalLoadingSpinnerWrapper>
  </Portal>
);

const ModalLoadingSpinnerWrapper = styled.div`
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999999;

  background-color: #00000080;

  @keyframes transitionIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  animation: 0.5s ease transitionIn;
`;

const ModalHandler: React.FC = () => {
  const openModals = useSelector(state => state.app.openModals);
  const closeModal = useActions(app.actions.closeModal);
  const openModalType = openModals.length
    ? openModals[openModals.length - 1].type
    : '';

  return openModals.length ? (
    <Suspense fallback={<ModalLoadingSpinner />}>
      <Modal
        closeModal={
          !UNSKIPPABLE_MODAL_TYPES.includes(openModalType)
            ? () => closeModal(true)
            : () => {}
        }
        open
        data-intersection-root
      >
        {openModals.map(({ type, props }, i) => {
          const C = getModalComponent(type);
          // Render all modals to preserve the state, but only display the last
          return C ? (
            <span
              style={{
                display: i !== openModals.length - 1 ? 'none' : undefined,
                width: '100%',
              }}
            >
              <C closeModal={closeModal} {...(props as any)} />
            </span>
          ) : null;
        })}
      </Modal>
    </Suspense>
  ) : null;
};

export default ModalHandler;
